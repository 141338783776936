<template>
 <div>
   <el-route-tabs class="el-tabs--menu"
                  type="border-card"
                  v-model="activeName"
                  :tab-list="tabList.map((d)=>{return {...d,label:d.name,name:d.value};})"
                  @tab-click="$handleRoute(activeName)"
   >
     <template slot="default" slot-scope="{params}">
       <router-view v-bind="{...$attrs,params:params||{}, isCollect:true}"></router-view>
     </template>
   </el-route-tabs>
<!--   <el-tabs v-model="activeName" @tab-click="$handleRoute(activeName)">-->
<!--     <el-tab-pane :label="tab.name" :name="tab.value"  v-for="(tab , index) in tabList" :key="index"></el-tab-pane>-->
<!--   </el-tabs>-->
<!--   <router-view v-bind="{params:(tabList.filter(d=>d.value===activeName)[0] || {}).params ||{}, isCollect:true}"></router-view>-->
 </div>
</template>

<script>
export default {
  name: "MeCollect",
  components:{},
  data() {
    return {
      activeName: 'meStudyColletCourse',
      tabList:[],
    };
  },
  methods: {
    userInfoListener(v){
      this.routeUpdate();
    },
    routeUpdate(){
      var tabList = [
        {name:'课程' , value:'meStudyColletCourse',params:{is_plan:0} },
        {name:'题单' , value:'meStudyColletQuestList' },
        {name:'题目' , value:'meStudyColletQuestion'},
        {name:'论坛' , value:'meStudyColletForum'},
      ];
      if(this.userInfo.type === 2){
        tabList.push({name:'教案' , value:'meStudyColletCoursePlan',params:{is_plan:1}})
      }
      this.tabList = tabList;
    },
    routeChange({routerViewDepth}){
      let $route = this.$route.matched[routerViewDepth + 1] || this.$route;
      let current = this.tabList.filter(d=>$route.name === d.value)[0] || this.tabList.filter(d=>$route.name.indexOf(d.value) === 0) || {};
      let activeName = current.value;
      if(!activeName){
        this.$handleRoute(this.tabList[0].value, true)
      }else{
        this.activeName = activeName;
      }
    },
  }
}
</script>

<style scoped>

</style>